import {
  Button,
  Icon,
  IconButton,
  Tooltip,
  Typography,
} from '@wisecare-tech/design-system-web';
import { GetSacConfig } from 'domain/usecases/sacConfig/remote';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { makeRemoteGetSacConfig } from 'main/factories/usecases/sacConfig/GetSacConfig';
import React, { useCallback, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  InfoActionsContainer,
  SacActionsButtonsContainer,
  SacSection,
} from './styles';
import { iModalSupportInformation } from './types';

export const SacActions: React.FC = () => {
  const [sacConfig, setSacConfig] = React.useState<
    GetSacConfig.Model | undefined
  >(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);

  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const loadSacConfig = useCallback(async () => {
    const loadConfig = makeRemoteGetSacConfig();
    setLoading(true);
    try {
      const response = await loadConfig.get({
        orgId: String(id),
      });
      setSacConfig(response);
    } catch (error) {
      toast.error('Erro ao carregar configurações do SAC');
    } finally {
      setLoading(false);
    }
  }, [id]);

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success('Copiado para a área de transferência');
  };

  const openSupportInformationModal = () => {
    const data: iModalSupportInformation = {
      ...sacConfig?.config,
      email: sacConfig?.config.supportEmail ?? '',
      phones: sacConfig?.config.phones ?? [],
      schedule: sacConfig?.config.schedule ?? [],
    };

    makeReduxActiveMessage().active({
      data,
      title: 'Título do modal',
      active: 'supportInformation',
      actionOk: () => history.push(`/sac/${id}/settings`),
    });
  };

  const openEmbedCodeModal = () => {
    const code = `<button onClick={() => window.open(https://agenda.homolog.v4h.cloud/sac/subject?token=${sacConfig?.config.apiKey}, '_blank')} />`;

    makeReduxActiveMessage().active({
      active: 'incorporationCode',
      actionOk: () => handleCopyToClipboard(code),
      data: {
        code,
      },
    });
  };

  useEffect(() => {
    loadSacConfig();
  }, [loadSacConfig]);

  return (
    <SacSection>
      <SacActionsButtonsContainer>
        <InfoActionsContainer>
          <Typography variant="b2_14semibold">SAC - AtendeFácil</Typography>
          <Typography variant="b4_14regular" color="black.4">
            Um canal de comunicação oferecido para resolver dúvidas,
            solicitações e fornecer suporte aos clientes por meio de chamadas de
            vídeo incorporados ao seu sistema.
          </Typography>
        </InfoActionsContainer>
        <Link to={`/sac/${id}/settings`}>
          <Button variant="secondary" size="sm">
            <Icon name="settings" />
            Configurações
          </Button>
        </Link>
        <Button
          variant="secondary"
          size="sm"
          disabled={!sacConfig}
          onClick={openEmbedCodeModal}
        >
          <Icon name="code" />
          Incorporar
        </Button>
        <Tooltip content="Ver informações">
          <IconButton
            variant="primary"
            icon="info"
            size="sm"
            disabled={loading}
            onClick={openSupportInformationModal}
          />
        </Tooltip>
      </SacActionsButtonsContainer>
    </SacSection>
  );
};
